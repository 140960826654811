<template>
  <div>
    <div class="w-full h-screen overflow-hidden relative">
      <div class="w-full px-16 gap-4 pt-4 absolute md:mt-0 top-0 flex justify-between items-center md: md:items-center">
        <typography size="main-xl">Albarra</typography>
        <div
          v-if="authStore.isAuthenticated && isClient"
          :class="{
            ' relative   h-12   flex items-center justify-center gap-2  select-none ': true,
            'border-b-0 rounded-b-none': navIsOpen,
          }"
        >
          <div
            class="flex gap-2 items-center justify-center py-2 px-4 rounded rounded-t cursor-pointer border border-primary"
            @click="toggleNav"
          >
            <img :src="authStore.twitterPhoto" class="w-6 h-6 rounded-full" />

            <Typography size="main-md" class="text-center whitespace-no-wrap">{{
              `@${authStore.twitterNickName}`
            }}</Typography>
            <span class="rounded-full"><LucideChevronDown /></span>
          </div>
          <div
            v-if="navIsOpen"
            class="flex rounded-b flex-col z-40 absolute p-4 top-0 mt-12 w-full gap-3 bg-transparent border border-primary border-t-0"
          >
            <div
              class="flex items-center text-lg gap-4 cursor-pointer"
              @click="logout"
            >
              <LucideLogOut /> Logout
            </div>
          </div>
        </div>
        <div
          v-else
          @click="login"
          :class="{
            'border rounded rounded-t py-1 px-2  md:h-12 border-primary md:py-2 md:px-4 flex items-center justify-center gap-2 cursor-pointer ': true,
            'border-b-0 rounded-b-none': navIsOpen,
          }"
        >
          <Typography size="main-md" class="text-center whitespace-no-wrap"
            >Log in with
          </Typography>
          <span class="rounded-full"
            ><img
              src="~/assets/svg/x-logo-twiter.svg"
              class="h-4 w-4 md:w-6 md:h-6 rounded-full"
          /></span>
        </div>
      </div>
      <img
        ref="image"
        class="w-full h-screen object-cover"
        :src="ilustratedPage?.image"
        alt=""
        @load="calculatePosition"
      />
      <span
        v-for="(pixel, index) in clickablePixels"
        :key="index"
        :style="{ left: pixel.x + 'px', top: pixel.y + 'px' }"
        class="flex items-center justify-center absolute"
      >
        <typography
          size="main-l"
          class="absolute bottom-0 mb-4 text-shadow-lg text-nowrap children:text-black"
          >{{ pixel.title }}</typography
        >
        <div
          class="animate-ping w-4 h-4 md:w-6 md:h-6 bg-primary absolute rounded-full"
        ></div>
        <div
          @click="() => handleClickablePixel(pixel)"
          class="w-4 h-4 md:w-6 md:h-6 bg-primary absolute cursor-pointer rounded-full"
        ></div>
      </span>
    </div>
    <ul
      class="px-10 lg:px-20 absolute mb-8 lg:mb-14 bottom-0 flex flex-col lg:!text-xl gap-2 justify-center lg:justify-start opacity-80"
    >
      <typography
        @click="openAboutModal"
        class="!font-extralight font-gotham cursor-pointer lg:!text-xl"
        >About</typography
      >
      <typography
        @click="openFaqModal"
        class="!font-extralight font-gotham cursor-pointer lg:!text-xl"
        >FAQ</typography
      >
      <a
        class="font-extralight font-gotham cursor-pointer"
        href="https://x.com/albarra_tdrc"
        target="_blank"
        >X</a
      >
      <a
        class="font-extralight font-gotham cursor-pointer"
        href="https://discord.gg/albarra"
        target="_blank"
        >Discord</a
      >
    </ul>
    <div
      class="w-full absolute bottom-0 flex gap-2 justify-center text-center lg:mb-4"
    >
      <typography
        @click="openTermsModal"
        class="mb-4 mr-4 !font-extralight !text-xs text-neutral-300 font-gotham cursor-pointer"
        >Terms & Conditions</typography
      >
      <typography
        @click="openPrivacyPolicy"
        class="mb-4 mr-4 !font-extralight !text-xs text-neutral-300 font-gotham cursor-pointer"
        >Privacy Policy</typography
      >
    </div>
    <AlbarraModal />
    <MobileFullModal/>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount } from "vue";
import AlbarraModal from "../components/modals/AlbarraModal.vue";
import Typography from "@/components/common/Typography.vue";
import { useRoute } from "vue-router";
import AlbarraModals from "~/components/modals/AlbarraModals.vue";
import MobileFullModal from "../components/mobile-full-modal.vue";
import { cmsStore } from "../stores/cmsStore";
import { useAuthStore } from "../stores/useAuthStore";
import { useModalsStore } from "../stores/useModalsStore";
import { useLoaderStore } from "../stores/useLoaderStore";

const cms = cmsStore();
const authStore = useAuthStore();
const modalsStore = useModalsStore();
const route = useRoute();
const useLoader = useLoaderStore()
const assetsUrl = useRuntimeConfig().public.assetsUrl;
const navIsOpen = ref(false);
const isClient = ref(false);
useLoader.setLoading(true);

function isDayOrNight() {
  const currentHour = new Date().getHours();
  if (currentHour >= 6 && currentHour < 18) {
    return "day";
  } else {
    return "night";
  }
}

const handleClickablePixel = (pixel) => {
  if (pixel.link === null) return;
  console.log(pixel.link);
  if (authStore.isAuthenticated && pixel.link.includes("/tomahawk")) {
    window.location.href = "/tomahawk/";
    return;
  } else if (!authStore.isAuthenticated && pixel.link.includes("/tomahawk")) {
    modalsStore.openModal("LOGIN");
    return;
  }
  useLoader.setLoading(true);
  if (pixel.link.includes("http://") || pixel.link.includes("https://")) {
    return window.open(pixel.link, "_blank");
  }
  return navigateTo(pixel.link);
};

const ilustratedPage = ref({
  image:
    "https://albarra.world/cms/assets/7b74a204-f06f-4ee1-9b3a-76a3fdd394c8",
});

const image = ref(null);

const clickablePixels = ref([]);

const calculatePosition = () => {
  if (!image.value) return;
  const { width, height } = image.value.getBoundingClientRect();
  clickablePixels.value.forEach((pixel) => {
    pixel.x = (pixel.x_axis / 100) * width;
    pixel.y = (pixel.y_axis / 100) * height + 4;
  });
};

const loadIllustratedPage = async () => {
  const params = {
    filter: {
      slug: "home",
    },
  };
  let result = await cms.get("illustrated_page", params);
  result = result.data;
  ilustratedPage.value = result[0];
  if (ilustratedPage.value.imageNight) {
    if (isDayOrNight() === "day") {
      ilustratedPage.value.image = `${assetsUrl}/${ilustratedPage.value.image}`;
    } else if (isDayOrNight() === "night") {
      ilustratedPage.value.image = `${assetsUrl}/${ilustratedPage.value.imageNight}`;
    }
  }
  console.log(ilustratedPage.value);
  loadClickablePixels();
};

function openAboutModal() {
  modalsStore.openModal("ABOUT");
}

function openFaqModal() {
  modalsStore.openModal("FAQ");
}

function openTermsModal() {
  modalsStore.openModal("TERMS");
}
function openPrivacyPolicy() {
  modalsStore.openModal("PRIVACY");
}

const loadClickablePixels = async () => {
  const params = {
    filter: {
      illustrated_page_id: ilustratedPage.value.id,
    },
  };
  let result = await cms.get("clickable_pixel", params);
  clickablePixels.value = result.data;
  calculatePosition();
};

const login = () => authStore.login();
const logout = () => authStore.logout();

const toggleNav = () => {
  navIsOpen.value = !navIsOpen.value;
};

onMounted(() => {
  loadIllustratedPage();
  window.addEventListener("resize", calculatePosition);

  // in 3 seconds, the modal will be opened
  isClient.value = true;
  const AWAIT_5_SECONDS = 5000;
  let loginTimeout = null;
  if (!authStore.isAuthenticated) {
    loginTimeout = setTimeout(() => {
      modalsStore.openModal("LOGIN");
    }, AWAIT_5_SECONDS);
  }

  if (authStore.isAuthenticated && route.query?.loging === "1") {
    modalsStore.openModal("SUCCESS_LOGIN");
  }
  onUnmounted(() => {
    window.removeEventListener("resize", calculatePosition);
    if (loginTimeout) {
      clearTimeout(loginTimeout);
    }
  });
  useLoader.setLoading(false);
});



definePageMeta({
  pageTransition: {
    name: "slide",
    mode: "out-in",
  },
});
</script>
  
<style>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s;
}
.slide-enter-from {
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
}
</style>
  